import React from 'react'
import Layout from '../components/layout'
import WorksSection from '../components/sections/works'
import PageHeader from '../components/pageHeader'
import ProjectList from '../components/projectList'
import { useStaticQuery, graphql, Link } from 'gatsby';
import SEO from '../components/seo'

export const query = graphql`
  {
    projects: allStrapiProject {
      nodes {
        name
        slug
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default function ProjectsPage() {
  const data = useStaticQuery(query);
  const projects = data.projects.nodes;
  return (
    <Layout>
      <SEO title="Live the Dream | Recent Work | Our Projects"/>
      <PageHeader
        title="Our Projects"
        subTitle="We are committed to provide excellent services and top-quality home and office furniture to our clients. Here’s the glimpse of our recent projects to get an idea and feel of our work."
      />
      <WorksSection projects={projects}/>
      <ProjectList projects={projects} />
    </Layout>
  )
}
