import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import ProjectTile from '../components/projectTile'
import React from 'react'

const ProjectList = props => {
    const { projects } = props
    return (
        <div className='container mx-auto'>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-32 md:gap-y-16">
                {projects.map(project => {
                    return (
                        <ProjectTile project={project}/>
                    )
                })}
            </div>
        </div>
    )
}

export default ProjectList