import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ProjectTile from '../../components/projectTile'
import ContactModal from '../contactModal'
import EyebrowHeader from '../eyebrowHeader'

export const query = graphql`
  {
    projects: allStrapiProject {
      nodes {
        name
        slug
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default function Projects() {
  const data = useStaticQuery(query)
  const projects = data.projects.nodes
  return (
    // featured products go here, replace
    <section className="container mx-auto my-16 relative">
      <div class="grid md:grid-cols-2 grid-rows-2 gap-y-8 gap-x-16">
        <div class="relative box row-span-2">
          <EyebrowHeader left text="Recent Works" />
          <h2 className="text-3xl md:text-5xl 2xl:text-6xl font-playFair py-4">Some of <em>our crafts</em> made with quality</h2>
          <ProjectTile project={projects[0]} />
          <div className="mt-28">
            <ContactModal />
          </div>
        </div>
        {projects.length > 1 &&
          <div className='w-full md:w-4/5'>
            <ProjectTile project={projects[1]} />
          </div>
        }
        {projects.length > 2 &&
          <div className='w-full md:w-4/5'>
            <ProjectTile project={projects[2]} />
          </div>
        }
      </div>
    </section>
  )
}
